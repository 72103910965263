<template>
  <div class="w-100">
    <div class="title-actions">
      <h5 class="title">{{$t('editMatch')}}</h5>
      <div class="btns">
        <b-button @click="$router.go(-1)">
          <i class="fa fa-arrow-left"></i>
        </b-button>
      </div>
    </div>
    <hr />
    <b-form class="row" @submit.prevent="save()">
      <div class="form-group col-12 col-md-6">
        <label for="local">{{ fixture.team_local_name }}</label>
        <b-input-group>
          <b-input-group-prepend>
            <b-button variant="info" @click="addGoal('l')">
              <i class="fa fa-plus px-2"></i>
            </b-button>
          </b-input-group-prepend>
          <b-form-input
            type="number"
            min="0"
            class="text-center font-weight-bold"
            readonly
            v-model="fixture.local_goals"
          ></b-form-input>
          <b-input-group-append>
            <b-button variant="danger" @click="subGoal('l')">
              <i class="fa fa-minus px-2"></i>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
      <div class="form-group col-12 col-md-6">
        <label for="local">{{ fixture.team_visit_name }}</label>
        <b-input-group>
          <b-input-group-prepend>
            <b-button variant="info" @click="addGoal('v')">
              <i class="fa fa-plus px-2"></i>
            </b-button>
          </b-input-group-prepend>
          <b-form-input
            type="number"
            min="0"
            class="text-center font-weight-bold"
            readonly
            v-model="fixture.visit_goals"
          ></b-form-input>
          <b-input-group-append>
            <b-button variant="danger" @click="subGoal('v')">
              <i class="fa fa-minus px-2"></i>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
      <div class="col-12">
        <label for="status">{{ $t('status') }}</label>
        <select name="status" id="status" class="form-control" v-model="fixture.status">
          <option value="EN PROGRAMACION" selected>{{ $t('programming') }}</option>
          <option value="JUGADO">{{ $t('played') }}</option>
          <option value="PERDIDO POR DEFAULT">{{ $t('notPresented') }}</option>
        </select>
      </div>
      <div class="col-12 mb-3">
        <label for="comments">{{ $t('comments') }}</label>
        <b-form-textarea v-model="fixture.comments"></b-form-textarea>
      </div>
      <div class="col-12">
        <b-button variant="primary" type="submit">{{ $t('save') }}</b-button>
        <b-button class="ml-3" @click="$router.go(-1)">{{ $t('cancel') }}</b-button>
      </div>
    </b-form>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      fixtureId: this.$route.params.fixtureId,
      teamId: this.$route.params.teamId,
      fixture: {}
    };
  },
  computed: {
    ...mapGetters(["lang", "user", "console"])
  },
  mounted() {
    document.title = `${this.$t("editMatch")} - ITFA`;
    this.fetchData();
  },
  methods: {
    save() {
      const payload = {
        comments: this.fixture.comments,
        local_goals: this.fixture.local_goals,
        visit_goals: this.fixture.visit_goals,
        status: this.fixture.status
      };
      const path = `auth/${this.lang}/${this.console}/teams/results/${this.fixtureId}/${this.teamId}/update-results`;
      this.$axios.put(path, payload).then(() => {
        if (this.fixture.status === "PERDIDO POR DEFAULT") {
          this.$router.push({
            name: "TeamBackResultsHome",
            params: { lang: this.lang, console: this.console }
          });
          return;
        }
        this.$router.push({
          name: "TeamBackResultsPlayers",
          params: {
            lang: this.lang,
            console: this.console,
            fixtureId: this.fixtureId,
            teamId: this.teamId
          }
        });
      });
    },
    subGoal(type) {
      if (type === "l") {
        if (this.fixture.local_goals > 0) {
          this.fixture.local_goals -= 1;
        }
      } else {
        if (this.fixture.visit_goals > 0) {
          this.fixture.visit_goals -= 1;
        }
      }
    },
    addGoal(type) {
      if (type === "l") {
        this.fixture.local_goals += 1;
      } else {
        this.fixture.visit_goals += 1;
      }
    },
    fetchData() {
      const path = `auth/${this.lang}/${this.console}/teams/results/${this.fixtureId}/${this.teamId}/edit`;
      this.$axios
        .get(path)
        .then(response => {
          this.fixture = response.data.data;
          if (this.fixture.check_local || this.fixture.check_visit) {
            this.$router.push({
              name: "TeamBackResultsEditMatchStatistics",
              params: {
                lang: this.lang,
                console: this.console,
                fixtureId: this.fixtureId
              }
            });
            return;
          }
          if (!this.fixture.wallcover && !this.fixture.players) {
            this.fixture.status = "EN PROGRAMACION";
          } else {
            if (this.wallcover && this.played) {
              this.fixture.status = "PERDIDO POR DEFAULT";
            } else {
              this.fixture.status = "JUGADO";
            }
          }
        })
        .catch(() => {
          this.$router.go(-1);
        });
    }
  }
};
</script>
